import React from "react";
import Styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/material";

function ProfilePictureSection(props: any) {
  const { profileInfo } = props;

  return (
    <ThumbnailGrid item xl={3} lg={4} xs={12}>
      {profileInfo.picture && (
        <Thumbnail>
          <img
            src={profileInfo.picture}
            alt="profile-picture"
            width={263}
            height={263}
          />
        </Thumbnail>
      )}
      {profileInfo.picture == null && <Skeleton variant="circular" width={263} height={263} />}
    </ThumbnailGrid>
  );
}

export default ProfilePictureSection;

const ThumbnailGrid = Styled(Grid)`
  & span {
    margin: 0 auto;
  }
`;

const Thumbnail = Styled.div`
    display:flex;
    img{
        width: 263px;
        height: 263px;
        border-radius: 50%;
        margin:auto;
        // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        background:grey;
        object-fit: cover;
    }
    @media screen and (max-width: 991px) { }
    @media screen and (max-width: 767px) {}
`;
