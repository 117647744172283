import Styled from '@emotion/styled'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import Grid from '@mui/material/Grid'
import Link from 'next/link'
import Button from 'shared/components/Button'
import ContactAgentDialog from 'shared/components/contact-agent-dialog'
import React, { useState } from 'react'
import { useTranslations } from 'next-intl'
import { sentEvent } from 'api/events/events.request'
import { useRouter } from 'next/router'

function ContactSection(props: any) {
  const [open, setOpen] = useState(false);
  const {  profileInfo} = props;
  const translation = useTranslations("agentProfile.profile");
  const hiddenContact = [
    9 // wilton
  ].includes(profileInfo.id);
  const handleClose = () => {
    setOpen(!open);
  };


  const ClickWhatsAppAgent = () => {
      sentEvent("whatsapp_contant_agent", profileInfo.id).then((response) =>
        console.log(response)
      );
  }
  return (
    <Grid item xl={4} lg={3} md={5} sm={6} xs={12}>
      <ContactInfoContent>
        <ContactInfoText>{translation("contact")}</ContactInfoText>
        <ContentInfo className="--Contacts">
          <Info className="--description --contact">
            <EmailIcon />{" "}
            <Link href={`mailto:${profileInfo.email}`}>
              <span>{profileInfo.email}</span>
            </Link>
          </Info>

          <Info className="--description --contact">
            <PhoneIphoneIcon />
            <Link
              href={`tel:${
                profileInfo.mobile ??
                profileInfo.phone ??
                profileInfo.phone2 ??
                "-"
              }`}
            >
              <span>
                {profileInfo.mobile ??
                  profileInfo.phone ??
                  profileInfo.phone2 ??
                  "-"}
              </span>
            </Link>
          </Info>
        </ContentInfo>
       {!hiddenContact && <ContainerButton>
          <Link
            passHref
            href={`https://wa.me/1${profileInfo.mobile}?text=Hola+estimado(a),+me+interesa+hacer+negocios`}
            >
            <Button 
            target='_blank'
              bg="--color-green-success" 
              component="a"
              className="whatsapp-agente"
              onClick={ClickWhatsAppAgent}
            >
              WHATSAPP {profileInfo.mobile}
            </Button>
          </Link>
          <Button bg="--color-gray-02" component="a" onClick={handleClose}>
            {translation("sendMessage")}
          </Button>
        </ContainerButton>}
      </ContactInfoContent>
      <ContactAgentDialog
        open={open}
        onClose={handleClose}
        entity_info={profileInfo}
        isAgency={false}
        classNameModalButton="enviar-formulario-contacto-agente"
        customClass='agent'
      />
    </Grid>
  );
}

export default ContactSection;

const ContactInfoContent = Styled.div`
    justify-self: flex-end;
    margin-left: auto;
    @media screen and (max-width: 991px) {
        margin-right: 0;        
    }
     @media screen and (max-width: 767px) {
        margin-right: auto;        
    }
`;

const ContactInfoText = Styled.p`
    font-size: 1rem;
    font-weight: 600;
    color: #262b30;
    border-top: 1px solid #CBCBCB;
    border-bottom: 1px solid #CBCBCB;
    padding: 15px 20px;
    margin-bottom: 20px;
    text-align: center;
`;

const ContainerButton = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a{
        margin:5px 0px;
        min-width:255px
    }   
`;

const ContentInfo = Styled.ul`
    list-style: none;
    
    @media screen and (max-width: 767px) {
        margin-bottom: 20px;
        
    }
    &.--Contacts{
        margin-bottom: 20px;
        & span{
          width:90%;
          
          margin: auto;
          display: flex;
          & svg{
            padding-right: 5px;
          }
          a{
          width:100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          }
        }
    }
`;

const Info = Styled.span` 
    display: block;
    font-size: 1.063rem;
    font-weight: 500;
    color:var(--color-blue-main);
    vertical-align: middle;
    &.--description{
      white-space: nowrap;
      span{
        font-family:"Arial"; //the font family no work on mac
        vertical-align: middle;
        color: #989898;
      }
      svg{
        color: #989898;
      }
        &.--contact{
            margin-top:10px;
        }
    }
`;

const AgencyImage = Styled.div`
    img{
        max-width: 191px;
        width: 100%;
    }
     @media screen and (max-width: 767px) {
        width: 158px;
        text-align: center;
        margin:auto;
    }

`;
