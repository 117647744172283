import Styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import en from 'lang/en.json'
import es from 'lang/es.json'
import Head from 'next/head'
import BuySellFilter from 'shared/components/buy-sell-filter'
import HeaderTitleProfile from 'shared/components/header-title-profile'
import React, { useEffect } from 'react'
import { IAgentResponse } from 'shared/models/interfaces/response/agent.interface'
import { MessageResults } from 'shared/components/messages-result'
import { GetStaticPropsContext } from 'next'
import { CPagesPath } from 'shared/models/const/pages-path'
import { useRouter } from 'next/router'
import { useSessionStorage } from 'usehooks-ts'
import { useTranslations } from 'next-intl'

import ContactSection from './components/contact-section'
import ProfilePictureSection from './components/profile-picture-section'
import SliderSection from './components/sliders-section'
import UserInfoSection from './components/user-info-section'
import Layout from '@components/layout'

export interface IAgentProfileContain {
  id: number;
  data: IAgentResponse;
  IsUserCollection: boolean
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      messages: locale === "es" ? es : en,
    },
  };
}

function AgentProfileContain(props: IAgentProfileContain) {
  const { id, IsUserCollection, data } = props;
  const router = useRouter();
  const [code, setCode] = useSessionStorage("code", { id: null, type: null });
  const translation = useTranslations("agentProfile");
  const isCollection = router.asPath.includes(CPagesPath.es.collection);
  const agencyTitle = translation("title") || "";

  const [title, ...rest] = agencyTitle.split(" ");

  useEffect(() => {
    const url = `${isCollection ? "/collection/agente/" : "/agente/"}${data.data.name
      .replaceAll(" ", "-")
      .toLowerCase()}/${id}?referer=${id}`;

    let agentCode: any = {
      type: "referer",
      id: id,
    };

    // setCode(agentCode);

    router.replace(url, undefined, { shallow: true });
  }, []);


  if (!data) return <MessageResults message="...error" />;

  const config = {
    profileInfo: adapterAgent(data),
    IsUserCollection,
  };

  const metaData = {
    description: `Agente inmobiliario ${config.profileInfo.name} de RE/MAX Republica Dominicana ubicada`,
    keyWords: 'Agente inmobiliario, Agente inmobiliario remax rd, Agente Remax, Remax agencia inmobiliaria, Agente Remax República Dominicana',
    url: process.env.NEXT_PUBLIC_DOMAIN_URL + router.asPath,
    type: 'Agente RE/MAX'
  }


  return (
    <Layout showFooter={true}>
      <>
        {config.profileInfo && (
          <Head>
            <title>Agente {config.profileInfo.name.toUpperCase() + "-RE/MAX Republica Dominicana"}</title>
            <meta name="description" content={metaData.description} />
            <meta property="og:url" content={metaData.url} />
            <meta property="og:type" content={metaData.type} />
            <meta property="og:title" content={"Agente " + config.profileInfo.name.toUpperCase()} />
            <meta property="og:image" content={config.profileInfo.picture} />
            <meta name="keywords" content={metaData.keyWords} />
          </Head >
        )}
        <Content>
          <HeaderTitleProfile label={rest.join(" ")} title={title} />
          <Card>
            <CardContainer className="container">
              <Grid container justifyItems="center" spacing={2}>
                <ProfilePictureSection {...config} />
                <UserInfoSection {...config} />
                <ContactSection {...config} />
              </Grid>
            </CardContainer>
            <FilterContainer>
              <BuySellFilter redirect={CPagesPath.es.properties} />
            </FilterContainer>
          </Card>
          {config.profileInfo.id && (
            <SliderSection {...config}></SliderSection>
          )}
        </Content>
      </>
    </Layout>

  );
}

const Content = Styled.div``;

const Card = Styled.div`
    padding-top: 50px;
    padding-bottom: 80px;
    background-color: #fff;
    @media screen and (max-width: 767px) {
        padding-bottom: 50px;
    }
`;

const CardContainer = Styled.div`
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #CBCBCB;
    padding-bottom: 50px;
    @media screen and (max-width: 991px) {
        justify-content: space-between;
    }   
    @media screen and (max-width: 767px) {
        flex-direction: column;
        &.container{data
            padding-bottom: 0;
        }
    }
`;

const FilterContainer = Styled.div`
    width: 100%;  
    margin: auto;
    display: flex;
    margin-top:30px ; 
    & .content-body{
      background:#F7F6F4;
      .MuiInputBase-root{
         background:#F7F6F4;
      }
    }

`;

export default AgentProfileContain;

const adapterAgent = (data: IAgentResponse) => {
  return {
    id: data.data?.id ?? null,
    picture: data.data?.picture ?? null,
    user_type_name: data.data?.user_type_name ?? "-",
    name: data.data?.name ?? "-",
    email: data.data?.email ?? "-",
    mobile: data.data?.mobile ?? "-",
    phone: data.data?.phone ?? "-",
    phone2: data.data?.phone2 ?? "-",
    agency_id: data.data?.agency_id ?? null,
    agency_name: data.data?.agency_name ?? "-",
    agency_picture: data.data?.agency_picture ?? null,
    social_networks: data.data?.social_networks ?? null,
    address: data.data?.address ?? "-",
    rating: data.data?.rating ?? 0,
    is_collection_agent: data.data?.is_collection_agent ?? 0
  };
};
