import React from "react";
import styled from "@emotion/styled";
import { TitleThickThin } from "../titles";

interface IHeaderTitleProfile {
  label: string;
  title?: string;
}
function HeaderTitleProfile(props: IHeaderTitleProfile) {
  return (
    <ContainerTitle>
      <TitleThickThin thickTitle={props.title || ""} thinTitle={props.label} />
    </ContainerTitle>
  );
}

export default HeaderTitleProfile;

const ContainerTitle = styled.article`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & h2 {
    margin: 0 !important;
  }
`;
