import React from "react";
import PropertiesSlider from "shared/components-pages/proyectos/PropertiesSlider";
import { CPagesPath } from "shared/models/const/pages-path";
import SliderCollectionProperty from "shared/components/slider-collection-property";
import Link from "next/link";
import Button from "shared/components/Button";
import Styled from "@emotion/styled";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";

function SliderSection(props: any) {
  const { profileInfo, IsUserCollection } = props;
  const translationProperties = useTranslations("agentProfile.properties");
  const translationCollection = useTranslations("agentProfile.collection");
  const collectionTitle = translationCollection("title") || "";
  const [title, ...rest] = collectionTitle.split(" ");
  const { asPath } = useRouter();
  const isCollection = asPath.includes(CPagesPath.es.collection);

  console.log(profileInfo.is_collection_agent)
  return (
    <div>
      <ContentSliderProperty>
        <PropertiesSlider
          subtitle=""
          title={translationProperties("title")}
          refRequest="property-list-normal-query"
          detailPath={CPagesPath.es.property_detail}
          withoutResultsComponent={
            <div style={{ marginTop: 20 }}>
              <Link
                href={CPagesPath.es.properties + `?referer=${profileInfo.id}`}
              >
                <Button component="a">Ver mas propiedades</Button>
              </Link>
            </div>
          }
          filters={{
            agent: profileInfo.id,
          }}
        >
          <Link href={CPagesPath.es.properties + `?referer=${profileInfo.id}`}>
            <Button component="a">{translationProperties("seeMore")}</Button>
          </Link>
        </PropertiesSlider>
      </ContentSliderProperty>
      
      {!!profileInfo.is_collection_agent && (
        <ContentSliderPropertyCollection>
          <SliderCollectionProperty
            title={title}
            subTitle={rest.join(" ")}
            arrowColor="#1f3564"
            filter={{
              agent: profileInfo.id,
            }}
          >
            <Link
              href={
                CPagesPath.es.collection_properties +
                `?referer=${profileInfo.id}`
              }
            >
              <Button component="a">{translationCollection("seeMore")}</Button>
            </Link>
          </SliderCollectionProperty>
        </ContentSliderPropertyCollection>
      )}
    </div>
  );
}

export default SliderSection;

const ContentSliderProperty = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:90%;
    margin: auto auto 50px;
    & section{
      margin-bottom:0px !important;
    }
`;

const ContentSliderPropertyCollection = Styled.div`
    margin: auto;
    width: 90%;
    text-align: center;
    margin: 80px auto;
    a{
      margin-top:10px;
      .collection__card__description{
        color:var(--color-blue-main);
        span{
        color:var(--color-blue-main);
        }
      }
    }
`;
