import { createFormData } from "@helpers/create-formdata.helper";
import { getRequest, postRequest } from "api/base-services.request";
import { AgentEndpoints } from "shared/models/endpoits/agent.endpoint";
import { IBaseFilter } from "shared/models/interfaces/base-filter-list";
import { IAgentResponse } from "shared/models/interfaces/response/agent.interface";
import { IAgentsResponse } from "shared/models/interfaces/response/agents.interface";

export interface IAgentsFilters extends IBaseFilter {
  collections?:boolean
  agency?:number
  q?:string
}
export const sentEvent = (nameEvent:string, agentId:number): Promise<IAgentsResponse> => {
  const body = { agent_id: agentId, event: nameEvent };
  return postRequest("/v2/event-register", body).then(
    (response) => response?.data
  );
};  








