import Styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import { Grid } from '@mui/material';
import React from 'react'

function UserInfoSection(props: any) {
  const { profileInfo } = props;

  return (
    <Grid item xl={5} lg={5} md={7} sm={6} xs={12}>
      <ContentDetail>
        <LabelNameUser className='nombre-agente'>{profileInfo.name}</LabelNameUser>
        <ContentInfo>
          <ContentInfoItem>
            {profileInfo.agency_picture && (
              <AgencyImage>
                <img
                  src={profileInfo.agency_picture}
                  alt={profileInfo.agency_name}
                  className="nombre-agencia"
                />
              </AgencyImage>
            )}
            {profileInfo.agency_picture == null && (
              <Skeleton variant="rounded" width={200} height={50} />
            )}
          </ContentInfoItem>
        </ContentInfo>
      </ContentDetail>
    </Grid>
  );
}

export default UserInfoSection
const ContentDetail = Styled.div`
        height: 100%;
        margin-top:15px;
        @media screen and (max-width: 991px) { }
        @media screen and (max-width: 867px) {
          width: 100%;
        margin: 0 auto 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        `;

const LabelNameUser = Styled.h1`
        font-size: 2.188rem;
        font-weight: 700;
        text-align: left;
        color:var(--color-blue-main);
        margin-bottom: 10px;

        span{
          font - weight: 300;
    }
        @media screen and (max-width: 767px) {
          text - align: center;
        margin-bottom: 20px;
        font-size: 1.788rem;
    }
        `;

const ContentInfo = Styled.ul`
        list-style: none;

        @media screen and (max-width: 767px) {
          margin - bottom: 20px;
        
    }
        &.--Contacts{
          margin - bottom: 20px;
        & span{
          width:70%;
        margin: auto;
        display: flex;
        & svg{
          padding - right: 5px;
          }
        }
    }
        `;

const ContentInfoItem = Styled.li`
        margin-bottom: 15px;
        `;

const Info = Styled.span`
        display: block;
        font-size: 1.063rem;
        font-weight: 500;
        color:var(--color-blue-main);
        &.--description{
          color: #989898;
        white-space: nowrap;
        &.--contact{
          margin - top:10px;
        }
    }
        `;

const AgencyImage = Styled.div`
        img{
          width: 20rem;
    }
        @media screen and (max-width: 767px) {
          display: flex;
          justify-content: center;
          width: 14rem;
        text-align: center;
        margin:auto;
    }

        `;